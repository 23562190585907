(function() {
	"use strict";

	var el, scrolled = null, th = 200;

	function init() {
		el = document.body.parentNode; // <html>

		onscroll();
		window.addEventListener("scroll", onscroll, false);
	}

	function onscroll() {
		var s = (el.scrollTop > th);
		if (s !== scrolled) {
			el.classList[s ? "add" : "remove"]("scrolled");
			scrolled = s;
		}
	}

	BS.DOMReady(init);
})();
