(function(global) {
	"use strict";

	var BS = global.BS;

	function toHiragana(str) {
		return str.replace(/[\u30a1-\u30f6]/g, function(match) {
			var chr = match.charCodeAt(0) - 0x60;
			return String.fromCharCode(chr);
		});
	}

	function toKatakana(str) {
		return str.replace(/[\u3041-\u3096]/g, function(match) {
			var chr = match.charCodeAt(0) + 0x60;
			return String.fromCharCode(chr);
		});
	}

	var stol = {
		"ぁ": "あ",
		"ぃ": "い",
		"ぅ": "う",
		"ぇ": "え",
		"ぉ": "お",
		"っ": "つ",
		"ゃ": "や",
		"ゅ": "ゆ",
		"ょ": "よ",
		"ゎ": "わ",
		"ァ": "ア",
		"ィ": "イ",
		"ゥ": "ウ",
		"ェ": "エ",
		"ォ": "オ",
		"ッ": "ツ",
		"ャ": "ヤ",
		"ュ": "ユ",
		"ョ": "ヨ",
		"ヮ": "ワ",
	};
	function toRegularKana(str) {
		return str.replace(/[\u3041-\u3096\u30a1-\u30f6]/g, function (match) {
			return (match in stol) ? stol[match] : match;
		});
	}

	if (!BS.string) {
		BS.string = {};
	}
	BS.string.toHiragana = toHiragana;
	BS.string.toKatakana = toKatakana;
	BS.string.toRegularKana = toRegularKana;

})(window);
