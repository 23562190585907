BS.DOMReady(function() {
	"use strict";

	document.addEventListener("click", function(event) {
		var linkE, targetId, targetE, offset;

		linkE = event.target && event.target.closest("a[href^='#']");
		if (!linkE) {
			return;
		}

		targetId = linkE.getAttribute("href");
		if (targetId.charAt(0) !== "#") {
			return;
		}
		targetId = targetId.substr(1);

		targetE = document.getElementById(targetId);
		if (!targetE) {
			return;
		}
		event.preventDefault();
		BS.Scroll.toElement(targetE);
	}, false);
});
