(function(global) {
	"use strict";

	/// touchmove イベントで実際にタッチされている要素を返す
	/// @param event: Event
	/// @return Node?
	function touchmoveTarget(event) {
		if (event.touches) {
			if (event.touches.length >= 2) {
				return null;
			}
			var touch = event.touches[0];
			return document.elementFromPoint(touch.clientX, touch.clientY);
		} else {
			return event.target;
		}
	}

	global.BS.event = {
		touchmoveTarget: touchmoveTarget,
	};
})(window);
