(function(ns) {
	"use strict";

	var ImageUploader
	, ContainerClass = "ziicnt"
	, DefaultQuality = 0.9
	, MaximumPixelCount = 2400 * 1800
	, TypeConvertTable = {
		"image/jpeg": "image/jpeg"
		, "image/gif": "image/png"
		, "image/png": "image/png"
		, "image/bmp": "image/jpeg"
	}
	, InputTemplate = '<input type="file" accept="image/*" class="ziif">'
	, RemoveButtonTemplate = '<button type="button" class="ziir">削除</button>'
	, errorDialog
	;

	errorDialog = new BS.Dialog("画像を追加できません", "", BS.DialogButton.OK);

	ImageUploader = function ImageUploader() {
		this.init.apply(this, arguments);
	};

	ImageUploader.prototype = {

		/// @param el: Element
		init: function(el) {
			var me = this, wrapE, uid;
			
			wrapE = $(el);
			me.origInputE = wrapE.find("input").eq(0).attr({type: "hidden"}).removeAttr("accept");
			
			// パラメータの読み込み
			me.size = me.parseSize(wrapE.attr("data-size"));
			me.quality = me.parseQuality(wrapE.attr("data-quality"));

			// DOM を用意
			me.containerE = $("<div>").addClass(ContainerClass);
			me.inputE = $(BS.Template.createFragment(InputTemplate)).appendTo(me.containerE);
			me.removeButtonE = $(BS.Template.createFragment(RemoveButtonTemplate).firstChild).appendTo(me.containerE);
			me.progressBarE = $("<div>").addClass("ziip").appendTo(me.containerE);
			me.progressE = $("<div>").addClass("ziiq").appendTo(me.progressBarE);
			wrapE.empty().append(me.containerE).append(me.origInputE);
			me.prevPreviewImage = null;

			// イベントを設定
			me.containerE
			.on("change", ".ziif", function() {
				me.fileChange.apply(me, arguments);
			})
			.on("dragenter", cancelEvent)
			.on("dragover", cancelEvent)
			.on("drop", function(event) {
				me.drop(event);
				cancelEvent(event);
			})
			;

			me.removeButtonE.on("click", function() {
				me.removeImage();
			});

			// 初期値があれば表示
			if ((uid = me.origInputE.val())) {
				me.setPreviewImage(zzl.uploadImagePrefix + uid);
			}
		}

		/// @param size: any
		/// @return size: {crop: boolean, width: number, height: number}?
		, parseSize: function(size) {
			var m;
			
			if (typeof size === "string") {
				if ((m = size.match(/^([1-9][0-9]*)[x,]([1-9][0-9]*)$/))) {
					return {
						crop: true
						, width: parseInt(m[1], 10)
						, height: parseInt(m[2], 10)
					};
				}
				else if ((m = size.match(/^< *([1-9][0-9]*)[x,]([1-9][0-9]*)$/))) {
					return {
						crop: false
						, width: parseInt(m[1], 10)
						, height: parseInt(m[2], 10)
					};
				}
			}
			return null;
		}

		/// @param quality: any
		/// @return quality: number
		, parseQuality: function(quality) {
			if (typeof quality === "string") {
				quality = parseFloat(quality);
				if (0 <= quality && quality <= 1) {
					return quality;
				}
			}
			return DefaultQuality;
		}

		/// ファイルが選択されたときのイベントハンドラ
		/// @param event: Event || jQuery.Event
		, fileChange: function(event) {
			if (event.target.files && event.target.files[0]) {
				this.readFile(event.target.files[0]);
			}
		}

		/// ファイルがドロップされたときのイベントハンドラ
		/// @param event: Event || jQuery.Event
		, drop: function(event) {
			var dataTransfer = event.dataTransfer || event.originalEvent.dataTransfer;
			if (dataTransfer && dataTransfer.files[0]) {
				this.readFile(dataTransfer.files[0]);
			}
		}

		/// ファイルを読み込んで didReadFile に処理を移す
		/// @param file: File
		, readFile: function(file) {
			var me = this, errorDialog;

			if (!file.type || !TypeConvertTable[file.type]) {
				errorDialog = new BS.Dialog("未対応の形式", "JPEG, PNG, GIF, BMP 画像を指定してください", BS.DialogButton.OK);
				errorDialog.show();
				return;
			}

			// Data URI, ArrayBuffer の両形式で読み込み、両方が揃ったら didReadFile() を実行。
			// ArrayBuffer のほうは Exif の回転情報を取得するために使用する
			$.when(me.readFileAsURL(file), me.readFileAsBinary(file)).done(function(src, bin) {
				me.didReadFile(src, bin, file);
			});
		}

		/// @param file: File
		/// @return jQuery.Promise
		, readFileAsURL: function(file) {
			var d = new $.Deferred, fileReader;
			
			if (zzl.createObjectURL) {
				d.resolve(zzl.createObjectURL(file)); // readAsDataURL() より高速
			}
			else {
				fileReader = new FileReader;
				fileReader.onload = function(event) {
					d.resolve(event.target.result);
				};
				fileReader.readAsDataURL(file);
			}
			
			return d.promise();
		}

		/// @param file: File
		, readFileAsBinary: function(file) {
			var d = new $.Deferred, fileReader;
			
			fileReader = new FileReader;
			fileReader.onload = function(event) {
				d.resolve(event.target.result);
			};
			fileReader.readAsArrayBuffer(file);

			return d.promise();
		}

		/// 読み込んだファイルを次の処理に渡し、readFileComplete をコールバックとして設定する
		/// @param src: string - Data URI として読み込んだ画像
		/// @param bin: ArrayBuffer - ArrayBuffer として読み込んだ画像
		/// @param file: File
		, didReadFile: function(src, bin, file) {
			var me = this, orientation, processor;

			orientation = zzl.exif.getOrientation(bin);
			bin = null; // メモリ解放

			if (me.size && me.size.crop) {
				processor = ns.Cropper;
			}
			else {
				processor = ns.Shrinker;
			}

			new processor(src, {
				size: me.size
				, orientation: orientation
				, maximumPixelCount: MaximumPixelCount
				, allowTransparency: (TypeConvertTable[file.type] !== "image/jpeg") // PNG, GIF は透過を許可
			}, function(image) {
				me.readFileComplete(image, file);
			});
		}

		/// didReadFile の呼び出し先で処理されたファイルを受け取り、アップロードに処理を移す
		/// @param image: {canvas: HTMLCanvasElement, width: number, height: number}
		/// @param file: File
		, readFileComplete: function(image, file) {
			var me = this, src;

			src = image.canvas.toDataURL(TypeConvertTable[file.type], me.quality);
			me.setPreviewImage(src, true);
			me.startUpload(file, src);

			// イベントを発火
			me.origInputE.trigger(ns.event.input);
			me.origInputE.trigger(ns.event.change);
		}

		/// プレビュー画像を設定する
		/// @param src: string - 画像のURL
		/// @param doNotSave: boolean - 退避しない場合は true
		, setPreviewImage: function(src, doNotSave) {
			var me = this, im, previewE;
			
			im = new Image;
			im.src = src;
			im.className = "ziipii";
			previewE = $("<div>").addClass("ziipi").append(im);
			
			me.removePreviewImage();
			me.containerE.addClass("zii-has-image").append(previewE);

			if (!doNotSave) {
				me.prevPreviewImage = src;
			}
		}

		/// プレビュー画像を削除する
		/// @param doNotSave: boolean - 退避しない場合は true
		, removePreviewImage: function(doNotSave) {
			var me = this;
			
			me.containerE.removeClass("zii-has-image").find(".ziipi").remove();

			if (!doNotSave) {
				me.prevPreviewImage = null;
			}
		}

		/// 退避したプレビュー画像に戻す
		, restorePreviewImage: function() {
			var me = this;
			if (me.prevPreviewImage) {
				me.setPreviewImage(me.prevPreviewImage);
			}
			else {
				me.removePreviewImage();
			}
		}
		
		/// アップロードを開始する
		/// @param file: File
		/// @param content: string - 画像の Data URI
		, startUpload: function(file, content) {
			var me = this, uploadTo = zzl.uploadImageTo;
			
			$.ajax({
				url: uploadTo
				, type: "POST"
				, data: {
					_VALIDPOST: zzl.env.session_id
					, content: content
					, name: file.name
				}
				, dataType: "json"
				
				// プログレスバーを表示するための設定
				, xhr: function() {
					var xhr = $.ajaxSettings.xhr();
					if (xhr.upload && xhr.upload.addEventListener) {
						xhr.upload.addEventListener("progress", function(event) {
							me.setProgressValue(event.loaded / event.total);
						}, false);
					}
					return xhr;
				}
				
			}).done(function(data) {
				me.onUploadComplete(data);
				
			}).fail(function(data) {
				me.onUploadFail((data.responseJSON && data.responseJSON.message) || "");
			});
		}

		/// アップロード完了
		, onUploadComplete: function(data) {
			var me = this;
			me.origInputE.val(data.uid);
			me.resetInput();
			me.setProgressVisible(false);

			// 直前のアップロード画像を設定
			me.prevPreviewImage = zzl.uploadImagePrefix + data.uid;

			// イベントを発火
			me.origInputE.trigger(ns.event.uploadComplete);
		}

		/// アップロード失敗
		, onUploadFail: function(error) {
			var me = this;
			if (!errorDialog.isShown) {
				errorDialog.setSubtitle(localizeErrorMessage(error));
				errorDialog.show();
			}
			me.restorePreviewImage(); // アップロード前に戻す
			me.resetInput();
			me.setProgressVisible(false);

			// イベントを発火
			me.origInputE.trigger(ns.event.uploadFail);
		}

		/// <input type="file"> をリセット
		, resetInput: function() {
			var me = this, inputE;
			inputE = $(BS.Template.createFragment(InputTemplate)).appendTo(me.containerE);
			me.inputE.before(inputE).remove();
		}

		/// @param visible: Bool
		, setProgressVisible: function(visible) {
			this.containerE[visible ? "addClass" : "removeClass"]("zii-progress");
		}

		/// @param value: Int - 0-1 の値
		, setProgressValue: function(value) {
			value = Math.round(parseFloat(value) * 100);
			if (value < 0) {
				value = 0;
			}
			else if (value > 100) {
				value = 100;
			}
			this.progressE.css({width: value + "%"});
		}
		
		, resetProgress: function() {
			this.progressE.removeAttr("style");
		}

		/// ファイルを削除する
		, removeImage: function() {
			var me = this;
			
			me.origInputE.val("");
			me.removePreviewImage();

			// イベントを発火
			me.origInputE.trigger(ns.event.clear);
			me.origInputE.trigger(ns.event.change);
		}
	};

	/// @param event: Event || jQuery.Event
	function cancelEvent(event) {
		event.preventDefault();
	}

	/// @param message: string
	/// @return message: string
	function localizeErrorMessage(message) {
		if (/\binternal\b|\bI\/O\b/i.test(message)) {
			return "アップロードに失敗しました";
		}
		else if (/\binvalid request\b/i.test(message)) {
			return "アップロードに失敗しました。ファイルサイズが大きすぎないかご確認ください";
		}
		else {
			return message;
		}
	}

	ns.ImageUploader = ImageUploader;
	
})(zzl.uploader);
