$(function() {
	var SEL = ".ajaxzip3-click";
	$(SEL).removeClass("hide");
	$(document).on(BS.Event.Tap, SEL, function(event) {
		var btn, form, zip1, zip2, pref, city, addr, val, val2, args;
		
		btn = $(event.target).closest(SEL);
		if (btn.length !== 1) {
			return;
		}

		// 各フォーム要素を特定する
		form = btn.closest(".ajaxzip3-group, form").eq(0);
		form.find("input, select").each(function() {
			var input, name;
			input = this;
			name = $(input).attr("name");
			if (name == null) {
				return;
			}
			
			name = name.replace(/^\w+\[([\w-]+)\]$/, "$1");
			name = name.replace(/[_-]/g, " "); // 区切り文字をスペースに置換

			if (/\b(?:zip|post ?code|postal ?code)2\b/.test(name)) {
				zip2 = input;
			}
			else if (/\b(?:zip|post ?code|postal ?code)1?\b/.test(name)) {
				zip1 = input;
			}
			else if (/\b(?:pref|prefecture)\b/.test(name)) {
				pref = input;
			}
			else if (/\b(?:city)\b/.test(name)) {
				city = input;
			}
			else if (/\b(?:addr|address)1?\b/.test(name)) {
				addr = input;
			}
		});

		if (!window.AjaxZip3 || !AjaxZip3.zip2addr || !zip1) {
			return;
		}

		// 郵便番号を正規化する
		val = $(zip1).val();
		if (typeof val !== "string") {
			return;
		}
		val = zen2han(val);
		
		if (zip2) {
			val2 = $(zip2).val();
			if (typeof val2 !== "string") {
				return;
			}
			val2 = zen2han(val2);
			$(zip1).val(val);
			$(zip2).val(val2);
		}
		else {
			if (/^[0-9]{7}/.test(val)) {
				$(zip1).val(val.replace(/^(...)/, "$1-"));
			}
			else {
				$(zip1).val(val);
			}
		}
		
		// 引数を組み立てる
		if (!pref) {
			pref = addr;
		}
		args = [zip1, zip2, pref];
		if (city) {
			args.push(city);
		}
		args.push(addr);
		
		AjaxZip3.zip2addr.apply(AjaxZip3, args);
	});

	function zen2han(val) {
		return val.replace(/[！-～]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
		})
		.replace(/[\u2212\uff0d\u30fc\u2012\u2013\u2014\u2015\u2500]/g, "-")
		.replace(/[\u200B\u200C\u200D\uFEFF\u2028\u2029\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000]/g, " ");
	}
});
