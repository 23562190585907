(function(global) {
	"use strict";

	var BS = global.BS;

	/// n 桁の文字列を m 桁になるよう先頭または末尾に指定された文字を埋める (n < m)
	/// (n >= m の場合は入力文字列をそのまま返す)
	/// @param str: String - 対象文字列
	/// @param num: Int - 桁数
	/// @param fill: String = "0" - 埋める文字
	/// @param fillRight: Boolean = false - 右詰めでなく左詰めにする
	function fill(str, num, fill, fillRight) {
		str = "" + str;
		if (str.length >= num) {
			return str;
		}
		if (fill == null) {
			fill = "0";
		}
		var f = fill.charAt(0).repeat(num - str.length);
		return fillRight ? (str + f) : (f + str);
	}

	/// 2つの文字列のレーベンシュタイン距離を求める
	function distance(str1, str2, replaceCost) {
		replaceCost = replaceCost || 1;

		var d = Array(str1.length + 1);

		BS.range(0, str1.length).forEach(function(i1) {
			d[i1] = Array(str2.length + 1);
			BS.range(0, str2.length).forEach(function(i2) {
				if (i1 === 0) {
					d[i1][i2] = i2;
				} else if (i2 === 0) {
					d[i1][i2] = i1;
				} else {
					d[i1][i2] = 0;
				}
			});
		});

		BS.range(1, str1.length).forEach(function(i1) {
			BS.range(1, str2.length).forEach(function(i2) {
				var cost = (str1[i1] === str2[i2]) ? 0 : replaceCost;
				d[i1][i2] = Math.min(
					d[i1 - 1][i2] + 1,
					d[i1][i2 - 1] + 1,
					d[i1 - 1][i2 - 1] + cost
				);
			});
		});

		return d[str1.length][str2.length];
	}

	BS.string = {
		fill: fill,
		distance: distance,
	};

})(window);
