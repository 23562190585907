(function() {
	"use strict";

	var DOM = BS.DOM;

	function FavoriteToggler(btn) {
		var me = this, hasError = false;

		me.btn = btn;
		me.active = btn.classList.contains("js-favorite");

		me.user_id = btn.getAttribute("data-user-id");
		me.maman_id = btn.getAttribute("data-maman-id");
		me.createAPI = btn.getAttribute("data-create");
		me.destroyAPI = btn.getAttribute("data-destroy");
		me.activeClass = btn.getAttribute("data-active-class") || null;
		me.inactiveClass = btn.getAttribute("data-inactive-class") || null;

		if (!me.user_id) {
			console.warn("FavoriteToggler: data-user-id attribute does not set.");
			hasError = true;
		}
		if (!me.maman_id) {
			console.warn("FavoriteToggler: data-maman-id attribute does not set.");
			hasError = true;
		}
		if (!me.createAPI) {
			console.warn("FavoriteToggler: data-create attribute does not set.");
			hasError = true;
		}
		if (!me.destroyAPI) {
			console.warn("FavoriteToggler: data-destroy attribute does not set.");
			hasError = true;
		}
		if (hasError) {
			return;
		}

		me.setActive(me.active);
		btn.addEventListener("click", function() {
			me.toggle();
		});
	}

	FavoriteToggler.prototype = {
		toggle: function() {
			var me = this, api, formData, active = me.active;

			formData = new FormData();
			formData.append("_VALIDPOST", zzl.env.session_id);
			formData.append("user_id", me.user_id);
			formData.append("maman_id", me.maman_id);

			api = active ? me.destroyAPI : me.createAPI;

			fetch(api, {
				method: "POST",
				body: formData,
				credentials: "same-origin",
			})
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				if (json.success) {
					me.setActive(!active);
				}
			})
		},
		setActive: function(active) {
			var me = this, add = "add", remove = "remove";
			if (me.activeClass) {
				me.btn.classList[active ? add : remove](me.activeClass);
			}
			if (me.inactiveClass) {
				me.btn.classList[active ? remove : add](me.inactiveClass);
			}
			me.active = active;
		},
	};

	BS.DOMReady(function() {
		[].forEach.call(DOM.qs(".js-toggle-favorite"), function(btn) {
			new FavoriteToggler(btn);
		});
	})
})();
