/// Enterキーによるフォームの送信を防止する
BS.DOMReady(function() {
	window.addEventListener("keypress", function(event) {
		var input = BS.DOM.closest("input:not([type=submit])", event.target);
		if (!input) {
			return;
		}
		var form = BS.DOM.closest("form.form-suppress-enter", input);
		if (!form) {
			return;
		}
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	});
});
