(function(global) {
	"use strict";

	function range(start, end, step) {
		if (!step) {
			step = 1;
		}
		step = (step < 0) ? -step : step;
		if (end < start) {
			step = -step;
		}
		var r = [];
		for (var i = start; (step > 0 && i <= end) || (step < 0 && i >= end); i += step) {
			r.push(i);
		}
		return r;
	}

	global.BS.range = range;

})(window);
