$(function() {
	var appear = false;
	var pagetop = $('.btn-pagetop');
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {  //スクロールしたら
			if (appear == false) {
				appear = true;
				pagetop.stop().animate({
					'bottom': '30px' //下からの位置に
				}, 300); //0.3秒かけて現れる
			}
		} else {
			if (appear) {
				appear = false;
				pagetop.stop().animate({
					'bottom': '-80px' //下からの位置に
				}, 300); //0.3秒かけて隠れる
			}
		}
	});
	pagetop.click(function () {
		$('body, html').animate({ scrollTop: 0 }, 500); //0.5秒かけてトップへ戻る
		return false;
	});
});
