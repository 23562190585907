(function() {
	"use strict";

	var DOM = BS.DOM;

	var filters = {
		date_ja: function(val) {
			var date = new BS.Date(val.date || "not set");
			return date.isInvalid() ? "" : [
				date.getFullYear(),
				"年",
				date.getMonth() + 1,
				"月",
				date.getDate(),
				"日"
			].join("");
		},
		br: function(val) {
			return BS.Entities.encode(val).replace(/\r?\n|\r/g, "<br>");
		},
	};

	var More = function(moreBtn) {
		var me = this, templateId, hasError;

		me.btn = moreBtn;
		me.target = DOM.closest(".js-more-target", moreBtn);
		me.sourceURL = moreBtn.getAttribute("data-source");
		me.lastId = moreBtn.getAttribute("data-last-id");
		me.lastCTime = moreBtn.getAttribute("data-last-ctime");

		templateId = moreBtn.getAttribute("data-template");
		me.template = templateId ? DOM.q("#" + templateId) : null;

		if (!me.target) {
			console.warn("More: .js-more-target element does not exist.");
			hasError = true;
		}
		if (!me.sourceURL) {
			console.warn("More: data-source attribute does not set.");
			hasError = true;
		}
		if (!me.template) {
			console.warn("More: data-template attribute does not set.");
			hasError = true;
		}
		if (hasError) {
			return;
		}

		if (!me.lastId) {
			DOM.remove(moreBtn);
		}

		moreBtn.addEventListener("click", function() {
			me.more();
		});
	};

	More.prototype = {
		more: function() {
			var me = this;
			var ctimeParam = me.lastCTime ? "&last_ctime=" + me.lastCTime : "";
			fetch(me.sourceURL + "?last_id=" + me.lastId + ctimeParam, {
				method: "GET",
				credentials: "same-origin",
			})
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				console.log(json);
				if (json.items) {
					var templateString = me.template.innerHTML;
					json.items.forEach(function(item) {
						var fragment = BS.Template.createFragment(templateString, item, filters);
						while (fragment.firstChild) {
							DOM.insertBefore(fragment.firstChild, me.target);
						}
					});
				}
				if (json.last_id) {
					me.lastId = json.last_id;
				} else {
					me.lastId = null;
					DOM.remove(me.btn);
				}
				if (json.last_ctime) {
					me.lastCTime = json.last_ctime;
				} else {
					me.lastCTime = null;
				}
			});
		},
	};

	BS.DOMReady(function() {
		[].forEach.call(document.querySelectorAll(".js-more"), function(moreBtn) {
			new More(moreBtn);
		});
	});

})();
