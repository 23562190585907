/*
	使いやすくした日付クラス

	month の値は伝統的に 1 月が 0 となることに注意

	// インスタンスの生成
	let date1 = new BS.Date(); // 現在日時
	let date2 = new BS.Date(date1); // clone
	let date3 = new BS.Date(new Date()); // 標準の Date インスタンスを使用して初期化
	let date4 = new BS.Date("2018-11-02 11:56:34+0900"); // 標準の Date が解析できる形式で初期化
	let date5 = new BS.Date(1541127438157); // 標準の Date が解析できる形式で初期化
	let date6 = date5.clone(); // クローンを生成

	// 比較
	date1.equals(date2);
	date1 < date2;
	date1 <= date2;
	date1 > date2;
	date1 >= date2;

	// 日付時刻の加算、減算
	let date7 = date6.modify({year: 1, month: -2, day: 3});

	// TODO: 日付の差の計算
*/
(function(global) {

	var fill = global.BS.string.fill;

	// class BSDate
	// @param val: BSDate | Date | string | integer | undefined
	function BSDate(val) {
		var me = this;
		if (val instanceof BSDate) {
			me.rawDate = new Date(val.rawDate.valueOf());
		} else if (val instanceof Date) {
			me.rawDate = new Date(val.valueOf());
		} else if (val != null) {
			me.rawDate = new Date(val);
		} else {
			me.rawDate = new Date();
		}
	}

	BSDate.prototype = {

		// @return boolean
		isInvalid: function() {
			return Number.isNaN(this.rawDate.valueOf());
		},

		// @return BSDate
		clone: function() {
			return new BSDate(this);
		},

		// @param other: BSDate
		// @return boolean
		equals: function(other) {
			return this.rawDate.valueOf() === other.rawDate.valueOf();
		},

		// Equivalent of Date.prototype.getFullYear()
		// @return int
		getYear: function() {
			return this.rawDate.getFullYear();
		},

		// Equivalent of Date.prototype.setFullYear()
		// @param year: int
		setYear: function(year) {
			this.rawDate.setFullYear(year);
		},

		// Equivalent of Date.prototype.getUTCFullYear()
		// @return int
		getUTCYear: function() {
			return this.rawDate.getUTCFullYear();
		},

		// Equivalent of Date.prototype.setUTCFullYear()
		// @param year: int
		setUTCYear: function(year) {
			this.rawDate.setUTCFullYear(year);
		},

		// まとめて値を設定する
		// @param values: [string : int]
		set: function(values) {
			var date = this.rawDate;
			Object.keys(values).forEach(function(unit) {
				var val = parseFloat(values[unit]);
				if (Number.isNaN(val)) {
					throw new Error("Invalid argument: values." + unit);
				}
				switch (unit) {
				case "year":
					date.setFullYear(val);
					break;
				case "month":
					date.setMonth(val);
					break;
				case "day":
					date.setDate(val);
					break;
				case "hour":
					date.setHours(val);
					break;
				case "minute":
					date.setMinutes(val);
					break;
				case "second":
					var big = Math.floor(val), small = val - big;
					date.setSeconds(big);
					date.setMilliseconds(Math.round(small * 1000));
					break;
				}
			});
		},

		// まとめて値を設定する
		// @param values: [string : int]
		setUTC: function(values) {
			var date = this.rawDate;
			Object.keys(values).forEach(function(unit) {
				var val = parseFloat(values[unit]);
				if (Number.isNaN(val)) {
					throw new Error("Invalid argument: values." + unit);
				}
				switch (unit) {
				case "year":
					date.setUTCFullYear(val);
					break;
				case "month":
					date.setUTCMonth(val);
					break;
				case "day":
					date.setUTCDate(val);
					break;
				case "hour":
					date.setUTCHours(val);
					break;
				case "minute":
					date.setUTCMinutes(val);
					break;
				case "second":
					var big = Math.floor(val), small = val - big;
					date.setUTCSeconds(big);
					date.setUTCMilliseconds(Math.round(small * 1000));
					break;
				}
			});
		},

		// @return string
		serialize: function() {
			return this.serializeDate() + " " + this.serializeTime();
		},

		// <input type="date"> の値として使用する文字列
		// @return string
		serializeDate: function() {
			var date = this.rawDate;
			return date.getFullYear() + "-" + fill(date.getMonth() + 1, 2) + "-" + fill(date.getDate(), 2);
		},

		// <input type="time"> の値として使用する文字列
		// @return string
		serializeTime: function() {
			var date = this.rawDate;
			return fill(date.getHours(), 2) + ":" + fill(date.getMinutes(), 2) + ":" + fill(date.getSeconds(), 2) + "." + fill(date.getMilliseconds(), 3);
		},

		// 指定した期間を加減算した新しい BSDate インスタンスを返す
		modify: function(amount) {
			var date = new Date(this.rawDate.valueOf());
			Object.keys(amount).forEach(function(unit) {
				var val = amount[unit];
				if (val == null) {
					return;
				}
				var floatVal = parseFloat(val);
				if (Number.isNaN(floatVal)) {
					throw new Error("Invalid argument: amount." + unit);
				}

				switch (unit) {
				case "year":
					date = new Date(date.getFullYear() + floatVal, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
					break;
				case "month":
					date = new Date(date.getFullYear(), date.getMonth() + floatVal, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
					break;
				case "week":
					date = new Date(date.valueOf() + floatVal * 7 * 24 * 3600 * 1000);
					break;
				case "day":
					date = new Date(date.valueOf() + floatVal * 24 * 3600 * 1000);
					break;
				case "hour":
					date = new Date(date.valueOf() + floatVal * 3600 * 1000);
					break;
				case "minute":
					date = new Date(date.valueOf() + floatVal * 60 * 1000);
					break;
				case "second":
					date = new Date(date.valueOf() + floatVal * 1000);
					break;
				}
			});
			return new BSDate(date);
		},
	};

	// Date のメソッドをそのまま受け継ぐもの
	["get", "getUTC", "set", "setUTC"].forEach(function(xet) {
		["Date", "Day", "Hours", "Milliseconds", "Minutes", "Month", "Seconds", "Time", "FullYear"].forEach(function(col) {
			var method = xet + col;
			BSDate.prototype[method] = function() {
				return this.rawDate[method].apply(this.rawDate, arguments);
			};
		});
	});
	[
		"getTime",
		"getTimezoneOffset",
		"setTime",
		"toISOString",
		"toJSON",
		"toLocaleDateString",
		"toLocaleString",
		"toLocaleTimeString",
		"toString",
		"toUTCString",
		"valueOf"
	].forEach(function(method) {
		BSDate.prototype[method] = function() {
			return this.rawDate[method].apply(this.rawDate, arguments);
		};
	});

	// @var BSDate
	BSDate.now = new BSDate();

	// @var BSDate
	BSDate.today = BSDate.now.clone();
	BSDate.today.set({hour: 0, minute: 0, second: 0});

	global.BS.Date = BSDate;

})(window);
